<template>
  <div class="salarySiginPassword">
    <van-nav-bar :title="title"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight"
                 :fixed="true" />
    <div class="fixedH"
         style="height: 1.22667rem;"></div>
    <div class="content">
      <ul class="content_ul"
          style="margin-top:25px;">
        <li @click="visitDetails(value, key)"
            v-for="(value, key) in data"
            :key="key">
          <div class="line"></div>
          <div class="mingxi">
            <div class="dnbi_wai">
              <div class="dnbi_nei">
                <div class="xuhao_box">
                  <div class="xuhao_num">{{ value.sbtype }}</div>
                </div>
                <div class="dijibi">{{ value.china }}</div>
              </div>
            </div>
            <div class="num_box">
              {{ $t('salarySiginList.sslTxt4') }}{{ value.totalmoney }}
              <span>{{ value.m_pay_type }}</span>
              <p>{{ value.remark }}</p>
            </div>
            <div class="img_box"
                 style="display:none;"
                 v-show="value.is_wxsign">
              <div class="svg_sigin">
                <svg-icon icon-class="zu820"></svg-icon>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <!-- <div class="userSignBox">
                <div class="signTitle">
                    <span>已签名</span>  
                </div>
                <div class="imgBox"></div>
            </div> -->
      <van-overlay :show="show"
                   @click="show = false">
        <div style="width:100%;height:100%;"
             class="wrapper"
             @click.stop>
          <signaturePad @getSpad="getSpad"
                        @closeSpad="closeSpad"
                        :show="show"></signaturePad>
        </div>
      </van-overlay>
    </div>
    <div class="footerBox">
      <div class="allSiginButton"
           @click="show = true"
           v-if="!isreturn && $t('salaryMonth.smTxt1')">
        {{ $t('salaryMonth.smTxt1') }}
      </div>
      <div class="allSiginButton"
           @click="onClickLeft"
           v-if="isreturn">
        {{ $t('salaryMonth.smTxt2') }}
      </div>
    </div>
  </div>
</template>
<script>
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
import { Dialog, Toast } from 'vant'
import signaturePad from '@components/signaturePad'
import {
  getEmpMonthSalaryMaster,
  signMonthSalaryAll,
  signMonthSalaryAllRawJson
} from '@api/wx.js'
export default {
  data () {
    let firstchangepwd = this.$route.query.firstchangepwd
    let isfirstlogin = this.$route.query.isfirstlogin
    let nosign = this.$route.query.nosign
    let use_pay_feedback = this.$route.query.use_pay_feedback
    let autoid = this.$route.query.autoid || 0
    return {
      autoid,
      use_pay_feedback,
      firstchangepwd,
      isfirstlogin,
      nosign,
      userInfo,
      show: false,
      yymm: '',
      title: '',
      data: [],
      baseurl: '',
      isreturn: true
    }
  },
  components: {
    signaturePad
  },
  watch: {},
  created () {
    this.yymm = this.$route.query.yymm
    this.title = this.$route.query.china
    this.getData(this.yymm)
  },
  methods: {
    onClickLeft () {
      // this.$router.push("/salarySiginList");
      this.$router.push({
        path: '/salarySiginList',
        query: {
          moduleno: this.$route.query.moduleno,
          firstchangepwd: this.firstchangepwd,
          isfirstlogin: this.isfirstlogin,
          nosign: this.nosign,
          use_pay_feedback: this.use_pay_feedback
        }
      })
    },
    onClickRight () { },
    onSearch () { },
    closeSpad () {
      this.show = false
    },
    visitDetails (value, key) {
      this.$router.push({
        path: '/salaryDetails',
        query: {
          moduleno: this.$route.query.moduleno,
          use_pay_feedback: this.use_pay_feedback,
          isOnlyOne: false,
          autoid: value.autoid,
          yymm: this.$route.query.yymm,
          china: this.$route.query.china,
          num: value.china,
          pay_month_title: value.pay_month_title,
          firstchangepwd: this.firstchangepwd,
          isfirstlogin: this.isfirstlogin,
          nosign: this.nosign
        }
      })
    },
    getData (yymm) {
      getEmpMonthSalaryMaster({
        empid: this.userInfo.empid,
        yymm: yymm,
        autoid: this.autoid
      }).then(res => {
        let data = res.data
        for (let i = 0; i < data.length; i++)
        {
          if (data[i].sbtype == 1)
          {
            data[i].china = this.$t('salaryMonth.smTxt3')
          } else if (data[i].sbtype == 2)
          {
            data[i].china = this.$t('salaryMonth.smTxt4')
          } else if (data[i].sbtype == 3)
          {
            data[i].china = this.$t('salaryMonth.smTxt5')
          } else if (data[i].sbtype == 4)
          {
            data[i].china = this.$t('salaryMonth.smTxt6')
          } else if (data[i].sbtype == 5)
          {
            data[i].china = this.$t('salaryMonth.smTxt7')
          } else if (data[i].sbtype == 6)
          {
            data[i].china = this.$t('salaryMonth.smTxt8')
          } else if (data[i].sbtype == 7)
          {
            data[i].china = this.$t('salaryMonth.smTxt9')
          }
          if (!data[i].is_wxsign)
          {
            this.isreturn = false
          }
        }
        this.data = data
        // if (data.length == 1)        {
        //   this.$router.push({ path: "/salaryDetails", query: { use_pay_feedback: this.use_pay_feedback, isOnlyOne: true, autoid: data[0].autoid, yymm: this.$route.query.yymm, china: this.$route.query.china, num: data[0].china, firstchangepwd: this.firstchangepwd, isfirstlogin: this.isfirstlogin, nosign: this.nosign } });
        // }
      })
    },
    getSpad (url) {
      signMonthSalaryAllRawJson({
        // signMonthSalaryAll({
        moduleno: this.$route.query.moduleno ? this.$route.query.moduleno : 730,
        empid: this.userInfo.empid,
        yymm: this.yymm,
        username: this.userInfo.username,
        filebase64str: url
      }).then(res => {
        console.log(res)
        if (res.iserror == 0)
        {
          Toast(this.$t('salaryMonth.smTxt10'))
          this.show = false
          this.getData(this.yymm)
          this.isreturn = true
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.salarySiginPassword {
  height: 100%;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .footerBox {
    height: 132px;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .allSiginButton {
      width: 60%;
      height: 90px;
      text-align: center;
      line-height: 90px;
      background: #2b8df0;
      color: #fff;
      font-size: 36px;
      border-radius: 12px;
    }
  }
  .content {
    width: 100%;
    height: calc(100% - 224px);
    overflow: auto;
    .content_ul {
      width: 100%;
      li {
        margin-top: 25px;
        background: #fff;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        height: 162px;
        align-items: center;
        .line {
          min-width: 6px;
          height: 100%;
          background: #2b8df0;
        }
        .mingxi {
          width: 100%;
          background: #fff;
          height: 100px;
          display: flex;
          flex-wrap: nowrap;
          .num_box {
            width: 100%;
            height: 100%;
            padding-top: 34px;
            font-weight: bold;
            font-size: 32px;
            padding-left: 56px;
            line-height: 15px;
            span {
              color: red;
            }
            p {
              color: rgb(160, 160, 160);
              font-size: 20px;
            }
          }
          .img_box {
            min-width: 167px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .svg_sigin {
              width: 100px;
              height: 100px;
              .svg-icon {
                width: 100%;
                height: 100%;
              }
            }
          }
          .dnbi_wai {
            width: 207px;
            min-width: 207px;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            border-right: 2px solid #cccccc;
          }
          .xuhao_box {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 5px;
          }
          .xuhao_num {
            width: 44px;
            height: 44px;
            border-radius: 44px;
            border: 2px solid #2b8df0;
            text-align: center;
            line-height: 44px;
            font-weight: bold;
            color: #2b8df0;
            font-size: 20px;
          }
          .dijibi {
            font-size: 32px;
            width: 100%;
            text-align: center;
            font-weight: bold;
          }
        }
      }
    }
    .userSignBox {
      width: 100%;
      background: #fff;
      margin-top: 20px;
      .signTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 28px;
        color: #666;
        height: 60px;
        padding-left: 3%;
        padding-right: 4%;
      }
      .imgBox {
        width: 100%;
        height: 100px;
        background: #fff;
      }
    }
  }
}
</style>
